#content-tio {
    position: relative;
    width: calc(100% - 280px);
    left: 280px;
    transition: .3s ease;
}

.Bienvenida {
    text-align: center;
}

.Bienvenida .TituloBienvenida {
    text-align: center;
}

.Bienvenida .ImagenBienvenida {
    margin-top: 8rem;
}

#sidebar.hide~#content-tio {
    width: calc(100% - 60px);
    left: 60px;
}

#content-tio {
    padding: 36px 24px;
    font-family: var(--poppins);
    max-height: calc(100vh - 56px);
    color: var(--dark);
}

#content-tio #ListaProfesionales select {
    margin-left: 4px;
}