@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.container-fluid {
    margin: 0 !important;
    padding: 0 !important;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

:root {
    --poppins: 'Poppins', sans-serif;
    --lato: 'Lato', sans-serif;

    --light: #F9F9F9;
    --blue: #3C91E6;
    --light-blue: #CFE8FF;
    --grey: #eee;
    --dark-grey: #AAAAAA;
    --dark: #342E37;
    --red: #DB504A;
    --yellow: #FFCE26;
    --light-yellow: #FFF2C6;
    --orange: #FD7238;
    --light-orange: #FFE0D3;
}

html {
    overflow-x: hidden;
}

body.dark {
    --light: #0C0C1E;
    --grey: #060714;
    --dark: #FBFBFB;
}

body {
    background: var(--grey);

}

/* SIDEBAR */

#sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    height: 100%;
    background: var(--light);
    z-index: 2000;
    font-family: var(--lato);
    transition: .3s ease;
    overflow-x: hidden;
    scrollbar-width: none;
}

#sidebar::--webkit-scrollbar {
    display: none;
}

#sidebar.hide {
    width: 60px;
}

.menu-hamburguesa {
    font-size: 30px;
    height: 48px;
    background: transparent;
    margin-left: 10px;
    border-radius: 48px 0 0 48px;
    padding: 4px;
    cursor: pointer;
    color: var(--dark);
}

#sidebar .side-menu {
    width: 100%;
    margin-top: 50px !important;
    margin: 0;
    padding: 0;
}

#sidebar .side-menu li {
    height: 48px;
    background: transparent;
    margin-left: 6px;
    border-radius: 48px 0 0 48px;
    padding: 4px;
}

#sidebar .side-menu li.active {
    background: var(--grey);
    position: relative;
}

#sidebar .side-menu li.active::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: -40px;
    right: 0;
    box-shadow: 20px 20px 0 var(--grey);
    z-index: -1;
}

#sidebar .side-menu li.active::after {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    bottom: -40px;
    right: 0;
    box-shadow: 20px -20px 0 var(--grey);
    z-index: -1;
}

#sidebar .side-menu li a {
    width: 100%;
    height: 100%;
    background: var(--light);
    display: flex;
    align-items: center;
    border-radius: 48px;
    font-size: 16px;
    color: var(--dark);
    white-space: nowrap;
    overflow-x: hidden;
}

#sidebar .side-menu.top li.active a {
    color: var(--blue);
}

#sidebar.hide .side-menu li a {
    width: calc(48px - (4px * 2));
    transition: width .3s ease;
}

#sidebar .side-menu li a.logout {
    color: var(--red);
}

#sidebar .side-menu.top li a:hover {
    color: var(--blue);
}

#sidebar .side-menu li a .icon_react {
    min-width: calc(60px - ((4px + 6px) * 2));
    display: flex;
    justify-content: center;
    color: var(--dark);
    
}

/* ESTILOS AL DAR CLICK PARA QUE APAREZCA EL DESPLEGABLE DE EMPRESAS */

.inactive {
    display: none;
}

.dropdown-company {
    display: grid;
    padding: 5px;
    width: 256px;
    background-color: var(--light);
    margin-left: 20px;

}

.dropdown-company a {
    width: 100%;
    height: 45px;
    text-align: left;
    text-decoration: none;
    color: black;
    transition: 500ms;
    border-radius: 50px;
    background-color: var(--grey);
    line-height: 45px;
    transition: 0.2s ease;
}

.dropdown-company a i {
    margin-left: 20px;
}

.dropdown-company:hover a {
    color: var(--blue);
}


/* ESTILOS DE LA VISTA EMPRESA */

/* ESTILOS DEL CONTENT */

#content-tio {
    position: relative;
    width: calc(100% - 280px);
    left: 280px;
    transition: .3s ease;
}

#sidebar.hide~#content-tio {
    width: calc(100% - 60px);
    left: 60px;
}

/* .icon_react {
    color: black
} */