* {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.encabezado {
    text-align: center;
    font-size: 30px;
}

.Datos {
    margin-top: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Inicia con 4 columnas */
    grid-gap: 10px;
    /* Espacio entre los elementos */
}

.txt_field {
    display: grid;
}

.txt_field input{
    width: 260px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #000;
    outline: none;
    margin-bottom: 30px;
    text-align: center;
    
}

.txt_field input::placeholder {
    text-align: center;
    /* Alinea el texto del placeholder al centro */
    line-height: normal;
    /* Restablece la altura de línea a su valor normal */
    color: black;
    /* Color del texto del placeholder */
    font-size: 17px;
    font-weight: 200;
}

.txt_field input:focus {
    border-bottom: 1px solid var(--blue);

}

.txt_field select {
    width: 260px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #000;
    outline: none;
    margin-bottom: 30px;
    font-size: 17px;
    font-weight: 200;
}

center {
    display: flex;
    gap: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.btn_cancelar,
.btn_registrar {
    width: 120px;
    height: 40px;
    border-radius: 4px;
    
}

.btn_registrar {
    background: green;
    color: #fff;
    border: #fff;
    transition: 0.3s;
}

.btn_registrar:hover {
    background: transparent;
    color: green;
    border: 1px solid green;
}

.btn_cancelar {
    background: red;
    color: #fff;
    border: #fff;
    transition: 0.3s;

}

.btn_cancelar:hover {
    background: transparent;
    color: red;
    border: 1px solid red;
}